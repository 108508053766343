import React from "react"
import { getUser } from "../services/auth"
import tw, { styled } from 'twin.macro';
import { graphql } from 'gatsby'

const Container = styled.div`
  ${tw`p-4 rounded-md border border-black border-solid`}
`

const Profile = ({data}) => {
  console.log(data)
  return <>
    <h1>Your profile</h1>
    <Container>

    <ul>
      <li>Name: {getUser().name}</li>
      <li>E-mail: {getUser().email}</li>
    </ul>
    </Container>
  </>
}





export default Profile

